<template>
  <v-dialog v-model="dialog" width="700" persistent scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">Modificar Ciclo</span>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="12" md="7">
              <v-text-field
                label="Nombre del Ciclo"
                required
                outlined
                hide-details
                v-model="cycle.cycle"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="5">
              <v-select
                :items="languages"
                item-text="language"
                item-value="id_language"
                label="Lenguaje"
                v-model="cycle.id_language"
                hide-details
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="6">
              <v-menu
                ref="menu"
                v-model="datePickerStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cycle.start"
                    label="Fecha de inicio"
                    readonly
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="cycle.start"
                  no-title
                  scrollable
                  @input="$refs.menu.save(cycle.start)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-menu
                ref="menu"
                v-model="datePickerEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="cycle.end"
                    label="Fecha de inicio"
                    readonly
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="cycle.end"
                  no-title
                  scrollable
                  @input="$refs.menu.save(cycle.end)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "UpdateCycle",
  data() {
    return {
      datePickerStart: false,
      datePickerEnd: false,
      isLoading: false,
      dialog: false,
    };
  },
  props: { cycle: Object, clean: { type: Function }, languages: Array },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + "cycle/" + this.cycle.id_cycle,
          this.toFormData(this.cycle)
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
};
</script>

<style>
.v-date-picker-table {
  height: auto !important;
}
</style>
