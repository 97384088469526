<template>
  <div>
    <v-banner>
      <div class="d-flex justify-space-between pr-4">
        <p class="text-h5 font-weight-medium mb-0 d-flex align-center">
          Ciclos
        </p>
        <!-- ********Acciones******** -->
        <v-btn
          color="primary"
          elevation="0"
          @click="modalAdd"
          v-if="searchInArray(actions, 'Agregar')"
        >
          <v-icon left light> mdi-plus </v-icon>
          <span class="d-none d-sm-flex">Agregar Ciclo</span>
          <span class="d-flex d-sm-none">Agregar</span>
        </v-btn>
      </div>
    </v-banner>

    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- **********Tabla************ -->
          <v-card>
            <v-data-table
              v-if="searchInArray(actions, 'Ver')"
              :headers="data.headers"
              :items="data.data"
              :item-per-page="5"
              :search="search"
              :loading="data.load"
              loading-text="Cargando datos..."
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="text-h5">
                    Lista de Ciclos
                  </v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-text-field
                    label="Buscar..."
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item>
                        <v-list-item-title>
                          <v-icon>mdi-file-excel</v-icon>
                          Descargar CSV
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </template>
              <!-- languages -->
              <template v-slot:[`item.language`]="{ item }">
                <v-chip
                  class="ma-2"
                  :color="
                    `${
                      cycleLanguage(item) !== 'Inglés'
                        ? 'primary'
                        : 'blue darken-2'
                    }`
                  "
                  outlined
                >
                  <span class="font-weight-medium">{{
                    cycleLanguage(item)
                  }}</span>
                </v-chip>
              </template>
              <!-- Actions -->
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="searchInArray(actions, 'Descargar CSV')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="
                          downloadCSVOfStudents(item);
                        "
                        color="green"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-file-delimited
                      </v-icon>
                    </template>
                    <span>Descargar CSV</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Editar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCycle(item);
                          modalUpdate();
                        "
                        color="amber"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Eliminar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCycle(item);
                          modalDelete();
                        "
                        color="red"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </template>
                <template v-if="searchInArray(actions, 'Activar/Desactivar')">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-5"
                        @click="
                          selectCycle(item);
                          changeStatus();
                        "
                        :color="
                          item.status == 1
                            ? 'light-blue darken-3'
                            : 'blue-grey darken-1'
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{
                          item.status == 1
                            ? "mdi-eye-outline"
                            : "mdi-eye-off-outline"
                        }}
                      </v-icon>
                    </template>
                    <span>
                      {{ item.status == 1 ? "Desactivar" : "Activar" }}
                    </span>
                  </v-tooltip>
                </template>
              </template>
              <!-- Si no carga la data -->
              <template v-slot:no-data>
                <v-btn color="primary" @click="getCycles()">
                  Recargar
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- Modals -->
    <AddCycle
      ref="mdlAdd"
      :cycle="newCycle"
      :clean="cleanAdd"
      :languages="availableLanguages"
    ></AddCycle>
    <UpdateCycle
      ref="mdlUpdate"
      :cycle="slcCycle"
      :clean="cleanUpdate"
      :languages="availableLanguages"
    ></UpdateCycle>
    <!-- Dialog Delete -->
    <v-dialog v-model="dialogDelete" max-width="300px">
      <v-card>
        <v-card-title class="headline">Advertencia</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <p class="mb-0 mt-4">
            ¿Está seguro de eliminar este elemento?
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="dark darken-1" text @click="closeDelete()">
            Cancelar
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteCycleConfirm()">
            Eliminar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddCycle from "./AddCycle";
import UpdateCycle from "./UpdateCycle";

export default {
  name: "Cycle",
  components: {
    AddCycle,
    UpdateCycle,
  },
  data: () => ({
    data: {
      load: true,
      headers: [
        {
          text: "Ciclo",
          value: "cycle",
        },
        {
          text: "Lenguaje",
          value: "language",
          align: "center",
        },
        {
          text: "Inicio",
          value: "start",
        },
        {
          text: "Fin",
          value: "end",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
        },
      ],
      data: [],
    },
    availableLanguages: [],
    newCycle: {},
    slcCycle: {},
    dialogDelete: false,
    isLoading: false,
    search: "",
  }),
  mounted() {
    this.getCurrentActions("Ciclos");
    this.getCycles();
    this.getLanguages();
  },
  methods: {
    //actions
    ...mapActions({
      getCurrentActions: "getCurrentActions",
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidadfuncionalidad
    getCycles: async function() {
      this.data.load = true;
      await this.$http
        .get(this.api + "cycle/all")
        .then(({ data }) => {
          this.data.data = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        })
        .then(() => {
          this.data.load = false;
        });
    },

    async getLanguages() {
      try {
        let response = await fetch(`${this.api}language/all`);
        let data = await response.json();
        if (data.length > 0) {
          this.availableLanguages = structuredClone(data);
          return;
        }
        throw new error("No hay lenguajes registrados.");
      } catch (error) {
        console.warning("languages couldn't be fetched rn. ", error);
      }
    },

    selectCycle(cycle) {
      this.slcCycle = cycle;
    },

    cycleLanguage(item) {
      return item.language.language;
    },

    cleanAdd() {
      this.newCycle = {};
      this.getCycles();
    },

    modalAdd() {
      this.$refs.mdlAdd.changeModal();
    },

    modalUpdate() {
      this.$refs.mdlUpdate.changeModal();
    },

    cleanUpdate() {
      this.slcCycle = {};
      this.getCycles();
    },

    modalDelete() {
      this.dialogDelete = true;
    },

    deleteCycleConfirm() {
      this.isLoading = true;
      this.$http
        .delete(this.api + "cycle/" + this.slcCycle.id_cycle)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.closeDelete();
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        })
        .then(() => {
          this.isLoading = false;
          this.closeDelete();
        });
    },

    changeStatus() {
      this.$http
        .get(this.api + "cycle/status/" + this.slcCycle.id_cycle)
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? "success" : "warning",
            message: data.message,
          });
          if (data.response) {
            this.cleanUpdate();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al realizar la petición",
          });
        });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.slcCycle = {};
      });
    },
    async downloadCSVOfStudents(cycle) {
      try {
        // Replace 'your-endpoint-url' with the actual endpoint URL
        const response = await this.$http
        .get(this.api + "cycle/" + cycle.id_cycle + "/students", {
          responseType: 'blob', // Important to handle binary data
        });

        // Create a new Blob object using the response data of the file
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a link to download it
        const link = document.createElement('a');
        link.href = url;

        const date = new Date();
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString();

        link.setAttribute('download', `Listado de estudiantes del ciclo ${cycle.cycle} ${day}-${month}-${year}.csv`);
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error('Error downloading the CSV file:', error);
      }
    }
  },
  computed: {
    ...mapGetters({
      api: "getApi",
      actions: "getCurrentActions",
    }),
  },
};
</script>

<style>
.v-tooltip__content {
  pointer-events: initial;
}
</style>
